import React from 'react';
import { Button, Container, LinearProgress, Typography } from '@material-ui/core';
import Img from 'gatsby-image';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectIsSubmittingRFA, selectRFASubmitError, selectRFASubmitSuccess } from '../selectors';
import SimpleUtilityForm from './SimpleUtilityForm';
import { graphql, useStaticQuery } from 'gatsby';

const RFAUtilityBills: React.FC = () => {
  const classes = useStyles();
  const submitSuccess = useSelector(selectRFASubmitSuccess);
  const error = useSelector(selectRFASubmitError);
  const isSubmittingRFA = useSelector(selectIsSubmittingRFA);

  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "mike.png" }) {
        childImageSharp {
          fluid(maxWidth: 128) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container maxWidth="sm" className={classes.root}>
      {!submitSuccess && (
        <Typography className={classes.title} variant="h5">
          How much is your electric bill?
        </Typography>
      )}

      {isSubmittingRFA ? (
        <LinearProgress className={classes.spacerTop} />
      ) : (
        <>
          {submitSuccess ? (
            <>
              <Typography className={classes.successTitle} variant="h5">
                One last step!
              </Typography>
              <Typography variant="body1">
                We have received your information and will send you a proposal you within 48 hrs, but let&apos;s first
                schedule a time for you to chat with your solar expert.
              </Typography>
              <div className={classes.avatarContainer}>
                <Img fluid={data.image.childImageSharp.fluid} className={classes.avatar} />
                <Button
                  className={classes.button}
                  color="primary"
                  size="medium"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    window.open(
                      'https://calendly.com/solvana/email-signature-meet-with-mike-from-solvana?month=2022-05',
                      '_blank',
                    );
                  }}
                >
                  Book a time with Mike
                </Button>
              </div>
              <Typography className={classes.successTitle} variant="h5">
                Thank you!
              </Typography>
            </>
          ) : (
            <>
              {error && (
                <Typography color="primary" variant="subtitle1">
                  {error}
                </Typography>
              )}

              <SimpleUtilityForm />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default RFAUtilityBills;
