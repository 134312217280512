import React from 'react';
import { useStyles } from '../styles';
import { MdExpandMore, MdExpandLess } from 'react-icons/all';
import { Collapse, List, ListItem } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';

type Props = {
  title: string;
};

const CollapsibleListItem: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.collapsibleLink}>
      <List className={classes.root}>
        <ListItem onClick={handleClick} className={classes.collapsibleListItemTitle}>
          <ListItemText>
            {title}&nbsp;
            {open ? <MdExpandLess className={classes.expandIcon} /> : <MdExpandMore className={classes.expandIcon} />}
          </ListItemText>
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" className={classes.nested} disablePadding>
            {children}
          </List>
        </Collapse>
      </List>
    </div>
  );
};

export default CollapsibleListItem;
