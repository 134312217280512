import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { RiCheckboxCircleFill } from 'react-icons/all';
import clsx from 'clsx';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  isSelected?: boolean;
  buttonClassName?: string;
  selectedText?: string;
  selectedClassName?: string;
  isSubmitting?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  type?: 'button' | 'submit';
};

const SelectButton: React.FC<Props> = ({
  onClick,
  isSelected,
  isSubmitting,
  buttonClassName,
  children,
  disabled,
  secondary,
  selectedClassName,
  selectedText,
  type,
  fullWidth,
}) => {
  const classes = useStyles()();

  if (isSelected) {
    return (
      <span
        className={clsx(classes.selectedContainer, {
          [selectedClassName || '']: Boolean(selectedClassName),
        })}
      >
        <RiCheckboxCircleFill />
        <Typography className={classes.selectedText} variant="subtitle1">
          {selectedText || 'Selected'}
        </Typography>
      </span>
    );
  }

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={clsx(classes.selectButton, {
        [classes.fullWidth]: fullWidth,
        [buttonClassName || '']: true,
        [classes.secondary]: secondary,
        [classes.submitting]: isSubmitting,
      })}
    >
      {children}
    </Button>
  );
};

export default SelectButton;
