import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsRFAVisible, selectRFASurveyQuestion } from './selectors';
import { setShowRFA } from './actions';
import { useStyles } from './styles';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import { RiCloseLine } from 'react-icons/all';
import { trackRFAEarlyExit } from '../../utils/analytics';
import RFAForm from './RFAForm';

const RFA: React.FC = () => {
  const classes = useStyles();
  const currentQuestion = useSelector(selectRFASurveyQuestion);
  const dispatch = useDispatch();
  const show = useSelector(selectIsRFAVisible);
  if (!currentQuestion) return null;

  const handleClose = () => {
    trackRFAEarlyExit({ step: currentQuestion.step });
    dispatch(setShowRFA(false));
  };

  return (
    <Dialog fullScreen={false} open={show} onClose={handleClose} maxWidth="md" fullWidth>
      <div className={classes.headerContainer}>
        <IconButton aria-label="close" onClick={handleClose}>
          <RiCloseLine size={38} />
        </IconButton>
      </div>
      <DialogContent className={classes.container}>
        <RFAForm />
      </DialogContent>
    </Dialog>
  );
};

export default RFA;
