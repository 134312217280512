import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors, isDarkMode } from '../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      padding: theme.spacing(0, 3, 6),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1, 4),
      },
      backgroundColor: isDarkMode(theme) ? Colors.Dark : 'inherit',
    },
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 1),
      margin: 0,
    },
    smallHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: theme.spacing(2, 1, 0, 2.5),
      height: '80px',
    },
    brand: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '168px',
      '& svg': {
        height: '42px',
      },
      [theme.breakpoints.down('md')]: {
        '& svg': {
          height: '36px',
        },
      },
    },
    userMenu: {
      cursor: 'pointer',
      paddingRight: theme.spacing(2.5),
      width: '168px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    appBar: {
      margin: 0,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(3, 2),
      width: '100%',
      backgroundColor: isDarkMode(theme) ? Colors.Dark : 'inherit',
    },
  }),
);
