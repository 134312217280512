import { Address, RFA, RFAContact } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain/dist';
import { Dispatch } from 'redux';
import rfaApi from '../../utils/apiClients';

export enum Actions {
  SetShow = 'RFASurvey/SetShow',
  SetCurrentStep = 'RFASurvey/SetCurrentStep',
  SetAddressField = 'RFASurvey/SetAddressField',
  SetContactField = 'RFASurvey/SetContactField',
  SetAddressState = 'RFASurvey/SetAddressState',
  SetIsSubmittingProfile = 'RFASurvey/SetIsSubmittingProfile',
  SetIsSubmittingRFA = 'RFASurvey/SetIsSubmittingRFA',
  SetRFASubmitError = 'RFASurvey/SetRFASubmitError',
  SetRFASubmitSuccess = 'RFASurvey/RFASubmitSuccess',
  ResetDefaultState = 'RFASurvey/ResetDefaultState',
}

export const setShowRFA = (show: boolean) => ({
  type: Actions.SetShow,
  show,
});

export const setCurrentStep = (currentStep: number) => ({
  type: Actions.SetCurrentStep,
  currentStep,
});

export const setIsSubmittingProfile = (isSubmittingProfile: boolean) => ({
  type: Actions.SetIsSubmittingProfile,
  isSubmittingProfile,
});

export const setRFASubmitSuccess = (submitSuccess: boolean) => ({
  type: Actions.SetRFASubmitSuccess,
  submitSuccess,
});

export const resetDefaultState = () => ({
  type: Actions.ResetDefaultState,
});

export const setIsSubmittingRFA = (isSubmittingRFA: boolean) => ({
  type: Actions.SetIsSubmittingRFA,
  isSubmittingRFA,
});

export const setRFASubmitError = (rfaSubmitError: string | null) => ({
  type: Actions.SetRFASubmitError,
  rfaSubmitError,
});

export type RFAAddressField = keyof Omit<Address, 'state'>;

export const setAddressField = (field: RFAAddressField, value: string) => ({
  type: Actions.SetAddressField,
  field,
  value,
});

export type RFAContactField = keyof RFAContact;

export const setContactField = (field: RFAContactField, value: string) => ({
  type: Actions.SetContactField,
  field,
  value,
});

export const setAddressState = (state: State) => ({
  type: Actions.SetAddressState,
  state,
});

export const submitRFA = (dispatch: Dispatch, rfa: RFA, onSuccess?: () => any) => {
  dispatch(setIsSubmittingRFA(true));
  dispatch(setRFASubmitError(null));

  rfaApi
    .post('/create-for-new-customer', rfa)
    .then(() => {
      dispatch(setRFASubmitSuccess(true));
      onSuccess && onSuccess();
    })
    .catch(() => {
      dispatch(setRFASubmitError('Oops...Something went wrong :-('));
    })
    .finally(() => {
      dispatch(setIsSubmittingRFA(false));
    });
};
