import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    success: {
      color: theme.palette.success.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    error: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);
