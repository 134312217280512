import React from 'react';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import HorizontalLogo from '../../../components/Logos/HorizontalLogo';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './styles';
import {
  Link as MUILink,
  Box,
  useTheme,
  useMediaQuery,
  useScrollTrigger,
  List,
  ListItem,
  MenuItem,
} from '@material-ui/core';
import DarkModeToggleButton from '../DarkModeToggleButton';
import { Routes } from '../../../utils/Routes';
import GetStartedButton from '../../../components/GetStartedButton/GetStartedButton';
import CircleLogo from '../../../components/Logos/CircleLogo';
import clsx from 'clsx';
import SideDrawer from './SideDrawer';
import CollapsibleLink from './CollapsibleLink';

type Props = {
  hideLogo?: boolean;
  hideLinks?: boolean;
  transparentBg?: boolean;
};

const Header: React.FC<Props> = ({ hideLogo, transparentBg, hideLinks }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 8,
  });
  const isDark = theme.palette.type === 'dark';
  const ServiceAreas = () => (
    <CollapsibleLink title="Service Areas">
      <MenuItem selected={location.pathname === Routes.ServiceAreas} onClick={() => navigate(Routes.ServiceAreas)}>
        Solar Energy Service Areas
      </MenuItem>
      <MenuItem selected={location.pathname === Routes.NjSolarEnergy} onClick={() => navigate(Routes.NjSolarEnergy)}>
        NJ Solar Energy
      </MenuItem>
      <MenuItem
        selected={location.pathname === Routes.SolarPanelInstallationFlorida}
        onClick={() => navigate(Routes.SolarPanelInstallationFlorida)}
      >
        Solar Panel Installation Florida
      </MenuItem>
      <MenuItem
        selected={location.pathname === Routes.TexasSolarPanelInstallation}
        onClick={() => navigate(Routes.TexasSolarPanelInstallation)}
      >
        Texas Solar Panel Installation
      </MenuItem>
    </CollapsibleLink>
  );

  if (transparentBg) {
    return (
      <AppBar
        position="fixed"
        color={transparentBg ? 'transparent' : 'default'}
        elevation={trigger ? 4 : 0}
        className={clsx(classes.root, {
          [classes.transparent]: transparentBg,
          [classes.backgroundColorTransition]: trigger,
        })}
      >
        <Toolbar className={classes.toolbar}>
          {!hideLogo && (
            <Link to={Routes.Home}>
              {isMobileView ? (
                <CircleLogo size="42px" />
              ) : (
                <HorizontalLogo height="42px" darkTitle={!trigger && isDark} />
              )}
            </Link>
          )}
          <Box component="nav" className={classes.bottomContainer}>
            {isMobileView ? (
              <List className={clsx(classes.list)}>
                <ListItem>
                  <DarkModeToggleButton dark={!trigger} />
                </ListItem>

                {!hideLinks && (
                  <ListItem>
                    <SideDrawer dark={!trigger} />
                  </ListItem>
                )}
              </List>
            ) : (
              <List className={clsx(classes.list, { [classes.darkText]: !trigger })}>
                {!hideLinks && (
                  <>
                    <ListItem>
                      <Link activeClassName={classes.activeLink} to={Routes.Home}>
                        Home
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link activeClassName={classes.activeLink} to={Routes.HowItWorks}>
                        How it works
                      </Link>
                    </ListItem>
                    <ServiceAreas />
                    <ListItem>
                      <Link activeClassName={classes.activeLink} to={Routes.ContactUs}>
                        Contact Us
                      </Link>
                    </ListItem>
                    <ListItem>
                      <MUILink href={Routes.SignIn}>Sign in</MUILink>
                    </ListItem>
                  </>
                )}
                <ListItem>
                  <GetStartedButton className={classes.button} title="Get a free proposal" />
                </ListItem>
                <ListItem>
                  <DarkModeToggleButton dark={!trigger} />
                </ListItem>
              </List>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar
      position="fixed"
      color={transparentBg ? 'transparent' : 'default'}
      elevation={trigger ? 4 : 0}
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        {!hideLogo && (
          <Link to={Routes.Home}>{isMobileView ? <CircleLogo size="42px" /> : <HorizontalLogo height="42px" />}</Link>
        )}

        <Box className={classes.bottomContainer}>
          {isMobileView ? (
            <List className={clsx(classes.list)}>
              <ListItem>
                <DarkModeToggleButton />
              </ListItem>
              <ListItem>
                <SideDrawer />
              </ListItem>
            </List>
          ) : (
            <List className={clsx(classes.list)}>
              <ListItem>
                <Link activeClassName={classes.activeLink} to={Routes.Home}>
                  Home
                </Link>
              </ListItem>
              <ListItem>
                <Link activeClassName={classes.activeLink} to={Routes.HowItWorks}>
                  How it works
                </Link>
              </ListItem>
              <ServiceAreas />
              <ListItem>
                <Link activeClassName={classes.activeLink} to={Routes.ContactUs}>
                  Contact Us
                </Link>
              </ListItem>
              <ListItem>
                <MUILink href="tel:888-205-2462">Or Call 888-205-2462</MUILink>
              </ListItem>
              <ListItem>
                <MUILink href={Routes.SignIn}>Sign in</MUILink>
              </ListItem>
              <ListItem>
                <GetStartedButton className={classes.button} title="Request a Proposal" />
              </ListItem>
              <ListItem>
                <DarkModeToggleButton />
              </ListItem>
            </List>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
