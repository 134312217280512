import * as React from 'react';
import { RFAIconProps } from '../../../components/SolvanaIcons/RFA/types';
import { RFASurveySteps } from '../routes';
import RFAAddress from '../RFAAddress';
import RFAUtilityBills from '../RFAUtilityBills';
import { Dispatch } from 'redux';
import { setCurrentStep } from '../actions';
import RFAContactInfo from '../RFAContact';
import { trackRFAStep } from '../../../utils/analytics';

export type RFASurveyAnswerChoice = {
  answerChoice: string;
  icon: React.FC<RFAIconProps>;
  iconWidth: string;
};

export enum QuestionType {
  Address = 'Address',
  Contact = 'Contact',
  UtilityBills = 'Utility Bills',
}

export enum RFAQuestion {
  Contact = 'How can we best contact you?',
  Address = 'Where would you like to install solar?',
  UtilityBills = 'Please grant access to review your utility history so that we can design options based on your energy demands and give you an accurate estimate on how much you will save with solar.',
}

export type RfaSurveyQuestions = {
  step: RFASurveySteps;
  stepHandler: () => string;
  question: RFAQuestion;
  answerChoices: RFASurveyAnswerChoice[];
  questionType: QuestionType;
};

export type SurveyStep = RfaSurveyQuestions & { component: React.FC };

const rfaSurveyQuestions: SurveyStep[] = [
  {
    step: RFASurveySteps.Contact,
    stepHandler: () => RFASurveySteps.Contact,
    questionType: QuestionType.Contact,
    answerChoices: [],
    question: RFAQuestion.Contact,
    component: RFAContactInfo,
  },
  {
    step: RFASurveySteps.Address,
    stepHandler: () => RFASurveySteps.Address,
    questionType: QuestionType.Address,
    answerChoices: [],
    question: RFAQuestion.Address,
    component: RFAAddress,
  },
  {
    step: RFASurveySteps.UtilityBills,
    stepHandler: () => RFASurveySteps.UtilityBills,
    questionType: QuestionType.UtilityBills,
    answerChoices: [],
    question: RFAQuestion.UtilityBills,
    component: RFAUtilityBills,
  },
];

export const getNextQuestion = (currentStep: number): SurveyStep | null => {
  if (currentStep < rfaSurveyQuestions.length - 1) {
    return rfaSurveyQuestions[currentStep + 1];
  }
  return null;
};

export const handleNextQuestionClick = (currentStepCount: number, dispatch: Dispatch) => {
  const nextQuestion = getNextQuestion(currentStepCount);

  if (nextQuestion) {
    const currentStep = rfaSurveyQuestions[currentStepCount].step;
    trackRFAStep({ currentStep, nextStep: nextQuestion.step });
    dispatch(setCurrentStep(currentStepCount + 1));
  }
};

export default rfaSurveyQuestions;
