import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { RiMenu3Line } from 'react-icons/all';
import { Routes } from '../../../../utils/Routes';
import { navigate } from 'gatsby';
import StyledButton from '../../../../components/StyledButton';
import clsx from 'clsx';
import { Colors } from '../../../../theme/Colors';
import { Link, MenuItem } from '@material-ui/core';
import CollapsibleListItem from '../CollapsibleListItem';

export const useStyles = makeStyles((_theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  dark: {
    '& svg': {
      fill: Colors.SecondaryDark,
    },
  },
}));

type Props = {
  dark?: boolean;
};

const SideDrawer: React.FC<Props> = ({ dark }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const navigateToRoute = (route: string, toggle = true) => {
    navigate(route);

    if (toggle) {
      toggleDrawer();
    }
  };

  const ServiceAreas = () => (
    <CollapsibleListItem title="Service Areas">
      <ListItem selected={location.pathname === Routes.ServiceAreas} onClick={() => navigate(Routes.ServiceAreas)}>
        Solar Energy Service Areas
      </ListItem>
      <ListItem selected={location.pathname === Routes.NjSolarEnergy} onClick={() => navigate(Routes.NjSolarEnergy)}>
        NJ Solar Energy
      </ListItem>
      <ListItem
        selected={location.pathname === Routes.SolarPanelInstallationFlorida}
        onClick={() => navigate(Routes.SolarPanelInstallationFlorida)}
      >
        Solar Panel Installation Florida
      </ListItem>
      <ListItem
        selected={location.pathname === Routes.TexasSolarPanelInstallation}
        onClick={() => navigate(Routes.TexasSolarPanelInstallation)}
      >
        Texas Solar Panel Installation
      </ListItem>
    </CollapsibleListItem>
  );

  return (
    <div>
      <React.Fragment>
        <Button className={clsx({ [classes.dark]: dark })} onClick={toggleDrawer}>
          <RiMenu3Line size={24} />
        </Button>
        <Drawer open={isOpen} anchor="right" onClose={toggleDrawer}>
          <div className={classes.list} role="presentation" onKeyDown={toggleDrawer}>
            <List>
              <ListItem button onClick={() => navigateToRoute(Routes.Home)}>
                <ListItemText>Home</ListItemText>
              </ListItem>
              <ListItem button onClick={() => navigateToRoute(Routes.HowItWorks)}>
                <ListItemText>How it works</ListItemText>
              </ListItem>
              <ServiceAreas />
              <ListItem button onClick={() => navigateToRoute(Routes.ContactUs)}>
                <ListItemText>Contact us</ListItemText>
              </ListItem>
              <ListItem>
                <Link href="tel:888-205-2462">Or Call 888-205-2462</Link>
              </ListItem>
              <ListItem button onClick={() => (window.location.href = Routes.SignIn)}>
                <ListItemText>Sign in</ListItemText>
              </ListItem>
              <ListItem>
                <StyledButton size="large" href={Routes.SignUp} variant="contained" color="primary" type="button">
                  Request a Free Proposal
                </StyledButton>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SideDrawer;
