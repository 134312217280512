import { Reducer } from 'redux';
import {
  Actions,
  resetDefaultState,
  setAddressField,
  setAddressState,
  setContactField,
  setCurrentStep,
  setIsSubmittingRFA,
  setRFASubmitError,
  setRFASubmitSuccess,
  setShowRFA,
} from './actions';
import { RFAQuestion } from './common/rfaSurveyQuestions';
import { Address, RFAContact } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain/dist';
import { ALL_RFA_STEPS } from './routes';

type RFAActions = ReturnType<typeof setCurrentStep> &
  ReturnType<typeof setAddressField> &
  ReturnType<typeof setContactField> &
  ReturnType<typeof setShowRFA> &
  ReturnType<typeof setAddressState> &
  ReturnType<typeof setIsSubmittingRFA> &
  ReturnType<typeof setRFASubmitError> &
  ReturnType<typeof resetDefaultState> &
  ReturnType<typeof setRFASubmitSuccess>;

type Survey = {
  [key in RFAQuestion]?: string[];
};

type RFAState = {
  show: boolean;
  currentStep: number;
  survey: Survey;
  address: Address;
  contact: RFAContact;
  isSubmittingRFA: boolean;
  submitSuccess: boolean;
  rfaSubmitError: string | null;
};

export type SelectRFAState = {
  rfa: RFAState;
};

const DEFAULT_STATE: RFAState = {
  show: false,
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  currentStep: 0,
  survey: {},
  isSubmittingRFA: false,
  submitSuccess: false,
  rfaSubmitError: null,
  address: {
    address1: '',
    address2: '',
    city: '',
    state: State.NJ,
    zip: '',
  },
};

const handleSetCurrentStep = (state: RFAState, currentStep: number): RFAState => {
  const totalStepCount = ALL_RFA_STEPS.length;
  const validStep = currentStep < 0 || currentStep > totalStepCount ? 0 : currentStep;

  return { ...state, currentStep: validStep };
};

const rfa: Reducer<RFAState, RFAActions> = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Actions.SetCurrentStep:
      return handleSetCurrentStep(state, action.currentStep);

    case Actions.ResetDefaultState:
      return DEFAULT_STATE;

    case Actions.SetRFASubmitSuccess:
      return {
        ...state,
        submitSuccess: action.submitSuccess,
      };

    case Actions.SetShow:
      return {
        ...state,
        show: action.show,
      };

    case Actions.SetRFASubmitError:
      return {
        ...state,
        rfaSubmitError: action.rfaSubmitError,
      };

    case Actions.SetAddressState:
      return {
        ...state,
        address: {
          ...state.address,
          state: action.state,
        },
      };

    case Actions.SetAddressField:
      return {
        ...state,
        address: {
          ...state.address,
          [action.field]: action.value,
        },
      };

    case Actions.SetContactField:
      return {
        ...state,
        contact: {
          ...state.contact,
          [action.field]: action.value,
        },
      };

    case Actions.SetIsSubmittingRFA:
      return {
        ...state,
        isSubmittingRFA: action.isSubmittingRFA,
      };

    default:
      return state;
  }
};

export default rfa;
