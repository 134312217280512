import React from 'react';
import { useStyles } from '../styles';
import { MdExpandMore, MdExpandLess } from 'react-icons/all';
import { Link as MUILink, Menu } from '@material-ui/core';

type Props = {
  title: string;
};

const CollapsibleLink: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.collapsibleLink}>
      <MUILink onClick={handleOpenMenu}>
        {title}
        {open ? <MdExpandLess className={classes.expandIcon} /> : <MdExpandMore className={classes.expandIcon} />}
      </MUILink>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {children}
      </Menu>
    </div>
  );
};

export default CollapsibleLink;
