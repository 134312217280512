import React from 'react';
import { Box, Container, Link as MUILink, List, ListItem, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Link } from 'gatsby';
import { Routes } from '../../../utils/Routes';
import HorizontalLogo from '../../../components/Logos/HorizontalLogo';
import { RiFacebookBoxFill, RiInstagramFill, RiTwitterFill, RiVimeoFill, RiYoutubeFill } from 'react-icons/all';

const Footer: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.border} />
      <Box component="footer" className={classes.root}>
        <Container maxWidth="lg">
          <div className={classes.logoContainer}>
            <Link to={Routes.Home}>
              <HorizontalLogo height="50px" whiteTitle />
            </Link>
            <List className={classes.socialIconList}>
              <ListItem>
                <MUILink target="_blank" href="https://www.facebook.com/gosolvana">
                  <RiFacebookBoxFill size="38px" />
                </MUILink>
              </ListItem>
              <ListItem>
                <MUILink target="_blank" href="https://www.instagram.com/solvanasolar">
                  <RiInstagramFill size="38px" />
                </MUILink>
              </ListItem>
              <ListItem>
                <MUILink target="_blank" href="https://twitter.com/gosolvana">
                  <RiTwitterFill size="38px" />
                </MUILink>
              </ListItem>
              <ListItem>
                <MUILink target="_blank" href="https://vimeo.com/solvana">
                  <RiVimeoFill size="38px" />
                </MUILink>
              </ListItem>
              <ListItem>
                <MUILink target="_blank" href="https://www.youtube.com/channel/UCshOE8Jc1IPYzBog2SgooBA">
                  <RiYoutubeFill size="38px" />
                </MUILink>
              </ListItem>
            </List>
          </div>
          <List className={classes.legalList}>
            <ListItem>
              <MUILink component={Link} to={Routes.PrivacyPolicy}>
                Privacy
              </MUILink>
            </ListItem>

            <ListItem>
              <MUILink component={Link} to={Routes.TermsAndConditions}>
                Terms
              </MUILink>
            </ListItem>

            <ListItem>
              <MUILink component={Link} to={Routes.CookiePolicy}>
                Cookies
              </MUILink>
            </ListItem>
          </List>

          <Typography className={classes.copyright} variant="body2">
            Copyright &copy; {new Date().getFullYear()} Solvana&trade; | All rights reserved | Solar PV & Energy Storage
            Systems since 2013
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
