import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(6, 3),
      backgroundColor: Colors.Dark,
      textAlign: 'center',
    },
    border: {
      height: theme.spacing(1),
      background: 'linear-gradient(270deg, #FBAC3B 0%, #FB563B 100%)',
    },
    copyright: {
      color: Colors.Info,
    },
    logoContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column',
      },
    },
    socialIconList: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      '& li': {
        padding: theme.spacing(0, 2, 0, 0),
        width: 'unset',
      },
      '& li:last-child': {
        padding: 0,
      },
      '& svg': {
        fill: theme.palette.primary.main,
        transition: 'fill .4s ease',
      },
      '& svg:hover': {
        fill: theme.palette.primary.light,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: theme.spacing(3),
        justifyContent: 'space-around',
        '& li': {
          padding: 0,
        },
      },
    },
    legalList: {
      display: 'flex',
      justifyContent: 'center',
      '& li': {
        padding: 0,
        marginRight: theme.spacing(2),
        width: 'unset',
      },
      '& li:last-child': {
        marginRight: 0,
      },
    },
  }),
);
