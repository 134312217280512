import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { Theme } from '@material-ui/core';
import { toFormattedPrice } from '../../../../../utils/formatters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(6),
    },
    slider: {
      '& root': {
        color: 'yellow',
      },
      '& .bar': {
        height: 9,
      },
      '& rail': {
        height: 8,
      },
    },
  }),
);

type Props = {
  title?: string;
  defaultValue?: number;
  min: number;
  max: number;
  step: number;
  onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
};

const DiscreteSlider: React.FC<Props> = ({ title, defaultValue, min, max, step, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && (
        <Typography id="discrete-slider" gutterBottom>
          {title}
        </Typography>
      )}
      <Slider
        className={classes.slider}
        defaultValue={defaultValue || 0}
        getAriaValueText={toFormattedPrice}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="on"
        onChange={onChange}
        step={step}
        min={min}
        max={max}
        valueLabelFormat={toFormattedPrice}
      />
    </div>
  );
};

export default DiscreteSlider;
