import React from 'react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRFAStep } from '../../containers/RFA/selectors';
import { setCurrentStep } from '../../containers/RFA/actions';

type StepHandlerFunc = {
  (stepHandlerId: string): string;
};

export type ProgressSlice = {
  title?: string;
  step: string;
  stepHandler: StepHandlerFunc;
};

type Steps = {
  number: number;
  title?: string;
  step: string;
};

type Props = {
  stepHandlerId?: number;
  pages: ProgressSlice[];
};

const ProgressBar: React.FC<Props> = ({ pages }) => {
  const classes = useStyles();
  const currentStep = useSelector(selectCurrentRFAStep);
  const dispatch = useDispatch();

  const getSteps = (): Steps[] => {
    return pages.map(({ title, step }, number) => ({
      number,
      title,
      step,
    }));
  };

  const steps = getSteps();

  return (
    <ul className={classes.root}>
      {steps.map((step, i) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li
          className={clsx({
            [classes.progress]: true,
            [classes.completedProgressItem]: step.number < currentStep,
            [classes.incompleteProgressItem]: step.number >= currentStep,
          })}
          onClick={() => {
            if (step.number < currentStep) {
              dispatch(setCurrentStep(i));
            }
          }}
          key={step.number}
        >
          {step.number === currentStep && (
            <div
              className={clsx({
                [classes.currentStep]: currentStep !== 0,
                [classes.firstCurrentStep]: currentStep === 0,
              })}
            >
              {step.title && (
                <Typography
                  variant="subtitle1"
                  className={clsx({
                    [classes.subtitles]: currentStep !== step.number,
                    [classes.currentStepSubtitle]: currentStep === step.number,
                  })}
                >
                  {step.title}
                </Typography>
              )}
            </div>
          )}

          {step.number !== currentStep && step.title && (
            <Typography
              variant="subtitle1"
              className={clsx(classes.subtitles, {
                [classes.futureStepSubtitle]: currentStep < step.number,
              })}
            >
              {step.title}
            </Typography>
          )}
        </li>
      ))}
    </ul>
  );
};

export default React.memo(ProgressBar);
