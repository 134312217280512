import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

type Props = {
  title?: string;
  desc?: string;
  banner?: string;
  pathname?: string;
  canonicalLink?: string;
  node?: {
    modifiedTime: string;
    birthTime: string;
  };
  individual?: boolean;
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: titleAlt
        defaultDescription: description
        defaultBanner: logo
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`;

const SEO: React.FC<Props> = ({ title, desc, banner, pathname, node, canonicalLink, individual }) => {
  const { site } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Organization',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: author,
    },
    copyrightYear: '2016',
    creator: {
      '@type': 'Organization',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: author,
    },
    datePublished: '2019-03-10T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  };
  
  
  const schemaOrganization = {
    '@context': 'https://schema.org',
    '@type':'Organization',
    'name': 'Solvana',
    'url': 'https://www.solvana.io/',
    'logo': 'https://www.solvana.io/favicon-32x32.png',
    'contactPoint': {
      '@type': 'ContactPoint',
      'contactType': 'contact',
      'telephone': '+18882052462'
    },
    'sameAs' : [ 
      'https://www.facebook.com/gosolvana',
      'https://www.instagram.com/solvanasolar',
      'https://twitter.com/gosolvana',
      'https://vimeo.com/solvana',
      'https://www.youtube.com/channel/UCshOE8Jc1IPYzBog2SgooBA']
  };
  
  const schemaService = {
    '@context': 'http://schema.org/',
    '@type': 'Service',
    'serviceType': 'Residential and commercial solar panel installation',
    'provider': {
      '@type': 'Organization',
      'name': 'Solvana',
      'image': 'https://www.solvana.io/static/12e52599212e6f9cf559a501c610c2e3/47498/solvana-solar-roof-1.jpg',    
      'telephone': '+18882052462'
    }
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
    {
      '@type': 'ListItem',
      item: {
        '@id': `${siteUrl}/about`,
        name: 'About',
      },
      position: 2,
    },
    {
      '@type': 'ListItem',
      item: {
        '@id': `${siteUrl}/projects`,
        name: 'About',
      },
      position: 3,
    },
    {
      '@type': 'ListItem',
      item: {
        '@id': `${siteUrl}/instagram`,
        name: 'About',
      },
      position: 4,
    },
  ];

  let schemaArticle = null;

  if (individual) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Organization',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Organization',
        name: author,
      },
      copyrightYear: '2019',
      creator: {
        '@type': 'Organization',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node ? node.birthTime : '2020-05-5T23:30:00+04:00',
      dateModified: node ? node.modifiedTime : '2020-05-5T23:30:00+04:00',
      description: seo.description,
      headline: seo.title,
      inLanguage: 'en',
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };

    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 5,
    });
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="facebook-domain-verification" content="kdb4z3fg2tk05ak2e9fv3vc80yne94" />
        <meta name="google-site-verification" content="NbySZHj6W52BFZeNQfgPJdWFnch_VKoXdfB9AJMew_U" />
        {!individual && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {individual && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
        <script type="application/ld+json">{JSON.stringify(schemaOrganization)}</script>
        <script type="application/ld+json">{JSON.stringify(schemaService)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
      </Helmet>

      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={individual ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />

      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  );
};

export default SEO;
