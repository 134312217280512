import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import ContactForm from './ContactForm';
import { handleNextQuestionClick } from '../common/rfaSurveyQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRFAStep, selectRFAContact, selectRFAContactHasErrors } from '../selectors';
import { RFAContactField, setContactField } from '../actions';
import SelectButton from '../../../components/SelectButton';

const RFAContactInfo: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const contact = useSelector(selectRFAContact);
  const currentStep = useSelector(selectCurrentRFAStep);
  const isButtonDisabled = useSelector(selectRFAContactHasErrors);

  const handleClick = () => {
    handleNextQuestionClick(currentStep, dispatch);
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5">Get Started</Typography>

      <ContactForm
        contact={contact}
        onFieldChangeHandler={(field: RFAContactField, value: string) => {
          dispatch(setContactField(field, value));
        }}
      />

      <SelectButton fullWidth disabled={isButtonDisabled} onClick={handleClick}>
        Next
      </SelectButton>
    </Container>
  );
};

export default RFAContactInfo;
