import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      width: '100%',
      margin: theme.spacing(2, 'auto', 0),
    },
    title: {
      margin: theme.spacing(1, 'auto', 1),
    },
    list: {
      margin: '0 auto',
      padding: theme.spacing(0, 2),
      textAlign: 'center',
      '& li': {
        padding: 0,
        listStyle: 'circle inside none',
        display: 'list-item',
      },
    },
    spacerTop: {
      marginTop: theme.spacing(5),
    },
    switchContainer: {
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    successTitle: {
      marginTop: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold,
    },
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(1),
    },
    answerOptionsContainer: {
      margin: theme.spacing(3, 0),
      width: '100%',
    },
    error: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    avatarContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      flexShrink: 1,
      marginTop: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      borderRadius: 999,
    },
  }),
);
