const SecondaryDark = '#12293a';

export const Colors = {
  Primary: '#e86247',
  Secondary: '#344b66',
  SecondaryDark,
  Dark: '#0E202D',
  Warning: '#f3ad3d',
  Info: '#49a2ba',
  Success: '#419192',
  Neon: '#1DDAAA',
  Background: '#fafafa',
  GreyOne: '#444d55',
  GreyTwo: '#72797f',
  GreyThree: '#a2a6aa',
  GreyFour: '#d0d2d5',
  GreyFive: '#e7e9ea',
  White: '#fff',
  DarkMode: {
    BrandTitle: '#fff',
    PaperBackground: SecondaryDark,
  },
};

export const radialSuccessGradient = 'radial-gradient(circle, #51CCDF 0%, #009393 100%)';
export const linearPrimaryGradient = '-webkit-linear-gradient(#FBAC3B, #FB563B)';

interface ITheme {
  palette: {
    type: 'dark' | 'light';
  };
}

export const isDarkMode = (theme: ITheme): boolean => theme.palette.type === 'dark';
