import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

const progressHeight = 24;
const borderWidth = 1;
const currentProgressItemHeight = progressHeight - 2 * borderWidth;
const baseTitleOffset = -28;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '600px',
      marginTop: 0,
      [theme.breakpoints.only('md')]: {
        maxWidth: '440px',
      },
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        margin: theme.spacing(1, 0),
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
      },
      display: 'flex',
      justifyContent: 'center',
      listStyleType: 'none',
      padding: 0,
      color: theme.palette.type === 'dark' ? Colors.GreyTwo : Colors.GreyFour,
      '& li': {
        margin: 0,
        padding: 0,
        textAlign: 'center',
        '&:first-child': {
          borderBottomLeftRadius: `${progressHeight}px`,
          borderTopLeftRadius: `${progressHeight}px`,
        },
        '&:last-child': {
          borderBottomRightRadius: `${progressHeight}px`,
          borderTopRightRadius: `${progressHeight}px`,
        },
      },
    },
    completedProgressItem: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer',
      },
    },
    incompleteProgressItem: {
      backgroundColor: theme.palette.type === 'dark' ? Colors.GreyOne : Colors.GreyFive,
    },
    currentStep: {
      backgroundColor: theme.palette.primary.light,
      height: `${currentProgressItemHeight}px`,
      borderBottomRightRadius: `${currentProgressItemHeight}px`,
      borderTopRightRadius: `${currentProgressItemHeight}px`,
      position: 'relative',
      top: 0,
      flexGrow: 1,
    },
    firstCurrentStep: {
      backgroundColor: theme.palette.primary.main,
      height: `${currentProgressItemHeight}px`,
      borderBottomRightRadius: `${currentProgressItemHeight}px`,
      borderTopRightRadius: `${currentProgressItemHeight}px`,
      borderBottomLeftRadius: `${progressHeight}px`,
      borderTopLeftRadius: `${progressHeight}px`,
      position: 'relative',
      top: 0,
      flexGrow: 1,
    },
    subtitles: {
      color: Colors.GreyTwo,
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontWeight: theme.typography.fontWeightMedium,
      position: 'relative',
      bottom: `${baseTitleOffset}px`,
      [theme.breakpoints.down('sm')]: {
        color: '#fff',
        bottom: 0,
      },
    },
    futureStepSubtitle: {
      color: Colors.GreyTwo,
    },
    currentStepSubtitle: {
      color: Colors.GreyTwo,
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontWeight: theme.typography.fontWeightMedium,
      position: 'relative',
      bottom: `${baseTitleOffset}px`,
      [theme.breakpoints.down('sm')]: {
        color: '#fff',
        bottom: 0,
        position: 'inherit',
      },
    },
    progress: {
      height: `${progressHeight}px`,
      flexGrow: 1,
      border: theme.palette.type === 'dark' ? `solid ${Colors.Dark} ${borderWidth}px` : `solid #fff ${borderWidth}px`,
    },
  }),
);
