import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { store } from './store';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import SEO from '../../components/SEO';
import { makeTheme } from '../../theme/materialUI/makeTheme';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Footer from './Footer';
import { selectLayoutUI } from '../common/layoutUI/selectors';
import RFA from '../RFA';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
    },
  }),
);

type Props = {
  hideFooter?: boolean;
  title: string;
  hideHeaderLinks?: boolean;
  withoutMarginTop?: boolean;
  withTransparentHeaderBg?: boolean;
  canonicalLink?: string;
  desc?: string;
};

const ThemeWrapper: React.FC<Props> = ({
  children,
  title,
  withoutMarginTop,
  withTransparentHeaderBg,
  hideFooter,
  hideHeaderLinks,
  canonicalLink,
  desc,
}) => {
  const { isDark } = useSelector(selectLayoutUI);
  const classes = useStyles();
  const theme = makeTheme(isDark);

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      <SEO title={title} canonicalLink={canonicalLink} desc={desc} />
      <main className={clsx({ [classes.root]: !withoutMarginTop })}>
        <Header transparentBg={withTransparentHeaderBg} hideLinks={hideHeaderLinks} />
        {children}
        <RFA />
        {!hideFooter && <Footer />}
      </main>
    </MUIThemeProvider>
  );
};

const Layout: React.FC<Props> = ({ ...props }) => (
  <Provider store={store}>
    <ThemeWrapper {...props} />
  </Provider>
);

export default Layout;
