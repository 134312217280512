import React from 'react';

import { Button, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setCurrentStep, setShowRFA } from '../../containers/RFA/actions';

export const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      textTransform: 'unset',
    },
  }),
);

type Props = {
  title: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const GetStartedButton: React.FC<Props> = ({ title, size, className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setShowRFA(true));
    dispatch(setCurrentStep(0));
  };

  return (
    <Button
      size={size || 'medium'}
      color="primary"
      className={clsx(classes.root, { [className || '']: Boolean(className) })}
      disableElevation
      variant="contained"
      onClick={handleClick}
    >
      {title}
    </Button>
  );
};

export default GetStartedButton;
