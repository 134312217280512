import React from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './styles';
import ProgressBar from '../../../components/ProgressBar';
import rfaSurveyQuestions from '../common/rfaSurveyQuestions';
import { AppBar } from '@material-ui/core';
import { selectRFASurveyQuestion } from '../selectors';

const RFAForm: React.FC = () => {
  const classes = useStyles();
  const currentQuestion = useSelector(selectRFASurveyQuestion);
  if (!currentQuestion) return null;

  return (
    <div className={classes.root}>
      <AppBar color="default" elevation={0} position="sticky" className={classes.smallProgressBarContainer}>
        <ProgressBar pages={rfaSurveyQuestions} />
      </AppBar>
      <currentQuestion.component />
    </div>
  );
};

export default RFAForm;
