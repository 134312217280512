import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    brand: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '80px',
      [theme.breakpoints.down('sm')]: {
        height: '60px',
      },
    },
    list: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& ul': {
        flexShrink: 1,
      },
      '& li': {
        flexShrink: 1,
        width: 'unset',
        padding: 0,
        margin: theme.spacing(0, 1, 0, 0),
      },
      '& li:last-child': {
        margin: 0,
      },
      '& a': {
        color: theme.palette.type === 'dark' ? '#fff' : Colors.Dark,
        fontWeight: 900,
        textTransform: 'capitalize',
        textDecorationLine: 'none',
        '&:hover': {
          textDecorationLine: 'none',
        },
      },
    },
    callUs: {
      fontSize: '1.4rem',
      // @ts-ignore
      fontWeight: `${theme.typography.fontWeightRegular} !important`,
      textDecoration: 'underline !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
      },
    },
    activeLink: {
      borderBottom: 'solid 2px',
    },
    button: {
      color: '#fff !important',
    },
    bottomContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      '& a': {
        marginRight: theme.spacing(3),
        textTransform: 'unset',
        [theme.breakpoints.down('sm')]: {
          marginRight: theme.spacing(1),
        },
      },
    },
    darkText: {
      color: Colors.SecondaryDark,
      '& a': {
        color: Colors.SecondaryDark,
      },
      '& svg': {
        fill: `${Colors.SecondaryDark} !important`,
      },
    },
    transparent: {
      backgroundColor: 'transparent',
    },
    backgroundColorTransition: {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(['background-color'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.short,
      }),
    },
    menu: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    collapsibleLink: {
      position: 'relative',
      cursor: 'pointer',
      marginTop: '4px',
    },
    expandIcon: {
      width: '1.5em',
      height: '2em',
      verticalAlign: 'middle',
    },
    nested: {
      '& li': {
        paddingLeft: theme.spacing(4),
      },
    },
    collapsibleListItemTitle: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }),
);
