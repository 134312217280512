import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors } from '../../theme/Colors';

const buttonPaddingMultiplier = 1;

export const useStyles = () =>
  makeStyles((theme: Theme) =>
    createStyles({
      selectButton: {
        width: '100%',
        maxWidth: '200px',
        fontSize: '1.05rem',
        textTransform: 'capitalize',
        letterSpacing: theme.spacing(0.08),
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
        '&:disabled': {
          backgroundColor: theme.palette.type === 'dark' ? Colors.GreyOne : Colors.GreyFour,
        },
        padding: theme.spacing(1),
        borderRadius: '8px',
        color: '#fff',
      },
      secondary: {
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.main : theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.type === 'dark' ? theme.palette.success.dark : theme.palette.secondary.dark,
        },
      },
      fullWidth: {
        maxWidth: '100%',
      },
      '@keyframes fadeIn': {
        from: { opacity: 0.3 },
      },
      submitting: {
        animation: '$fadeIn 1s infinite alternate',
      },
      selectedContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        color: theme.palette.success.main,
        '& svg': {
          width: theme.spacing(3.5),
          height: theme.spacing(3.5),
          fill: theme.palette.success.main,
          paddingRight: theme.spacing(1),
        },
      },
      selectedText: {
        color: theme.palette.success.main,
        padding: theme.spacing(buttonPaddingMultiplier),
      },
    }),
  );
