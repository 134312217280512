import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Colors, isDarkMode } from '../../../theme/Colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(0, 1, 4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 0, 2),
      },
      backgroundColor: 'unset',
    },
    smallProgressBarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: 0,
      margin: 0,
      backgroundColor: isDarkMode(theme) ? Colors.Dark : theme.palette.background.paper,
    },
  }),
);
