export enum Routes {
  CookiePolicy = '/cookie-policy/',
  Home = '/',
  SignUp = 'https://www.go.solvana.io/signup/',
  SignIn = 'https://www.go.solvana.io/signin/',
  HowItWorks = '/how-it-works/',
  ContactUs = '/contact-us/',
  PrivacyPolicy = '/privacy-policy/',
  TermsAndConditions = '/terms-and-conditions/',
  ServiceAreas = '/service-areas/',
  NjSolarEnergy = '/nj-solar-energy/',
  SolarPanelInstallationFlorida = '/solar-panel-installation-florida/',
  TexasSolarPanelInstallation = '/texas-solar-panel-installation/',
  SmartFastEasy = '/smart-fast-easy/',
}
