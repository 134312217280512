import { combineReducers } from 'redux';
import layoutUI from '../common/layoutUI/reducer';
import rfa from '../RFA/reducer';

const rootReducer = combineReducers({
  layoutUI,
  rfa,
});

export default rootReducer;
