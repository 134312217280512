import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { useStyles } from './styles';
import { rfaContactSchema, RFAContact } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain';
import { RFAContactField } from '../../actions';
import { trackRFAStarted } from '../../../../utils/analytics';
import { useLocation } from '@reach/router';

export interface OnFieldChangeHandler {
  (field: RFAContactField, value: string): any;
}

export interface OnStateFieldChangeHandler {
  (state: State): any;
}

type Props = {
  contact: RFAContact;
  onFieldChangeHandler: OnFieldChangeHandler;
};

const ContactForm: React.FC<Props> = ({ contact, onFieldChangeHandler }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Formik initialValues={{ ...contact }} validationSchema={rfaContactSchema} onSubmit={() => undefined}>
      {({ status, setFieldValue }) => (
        <Form>
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('firstName', e.target.value);
              setFieldValue('firstName', e.target.value);
            }}
            className={classes.input}
            name="firstName"
            onBlur={() => trackRFAStarted({ location: location.pathname })}
            type="text"
            label="First Name"
          />
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('lastName', e.target.value);
              setFieldValue('lastName', e.target.value);
            }}
            className={classes.input}
            name="lastName"
            type="text"
            label="Last Name"
          />
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('email', e.target.value);
              setFieldValue('email', e.target.value);
            }}
            className={classes.input}
            name="email"
            type="email"
            label="Email"
          />

          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('phoneNumber', e.target.value);
              setFieldValue('phoneNumber', e.target.value);
            }}
            className={classes.input}
            name="phoneNumber"
            type="tel"
            label="Phone Number"
          />

          {status && status.success && (
            <Typography variant="subtitle1" className={classes.success}>
              {status.success}
            </Typography>
          )}

          {status && status.error && (
            <Typography variant="subtitle1" className={classes.error}>
              {status.error}
            </Typography>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
