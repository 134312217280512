import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import { MenuItem, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { STATE_VALUES } from '@solvana/state-domain/dist';
import { useStyles } from './styles';
import { Address, addressSchema } from '@solvana/rfa-domain';
import { State } from '@solvana/state-domain';
import { RFAAddressField } from '../../actions';

export interface OnFieldChangeHandler {
  (field: RFAAddressField, value: string): any;
}

export interface OnStateFieldChangeHandler {
  (state: State): any;
}

type Props = {
  address: Address;
  stateValueOverride?: State[];
  onFieldChangeHandler: OnFieldChangeHandler;
  onStateFieldChangeHandler: OnStateFieldChangeHandler;
};

const allStateOptions = STATE_VALUES.map((stateValue) => ({ value: stateValue, label: stateValue }));

const AddressForm: React.FC<Props> = ({
  address,
  stateValueOverride,
  onFieldChangeHandler,
  onStateFieldChangeHandler,
}) => {
  const classes = useStyles();
  const stateOptions = stateValueOverride
    ? stateValueOverride.map((state) => ({ value: state, label: state }))
    : allStateOptions;

  return (
    <Formik initialValues={{ ...address }} validationSchema={addressSchema} onSubmit={() => undefined}>
      {({ status, setFieldValue }) => (
        <Form>
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('address1', e.target.value);
              setFieldValue('address1', e.target.value);
            }}
            className={classes.input}
            name="address1"
            type="text"
            label="Address1"
          />
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('address2', e.target.value);
              setFieldValue('address2', e.target.value);
            }}
            className={classes.input}
            name="address2"
            type="text"
            label="Address2"
          />
          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('city', e.target.value);
              setFieldValue('city', e.target.value);
            }}
            className={classes.input}
            name="city"
            type="text"
            label="City"
          />

          <Field
            component={TextField}
            className={classes.input}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setFieldValue('state', e.target.value);
              onStateFieldChangeHandler(e.target.value as State);
            }}
            name="state"
            label="State"
            select
            variant="standard"
            helperText="Please select A State"
            margin="normal"
            InputLabelProps={{ shrink: true }}
          >
            {stateOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>

          <Field
            component={TextField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onFieldChangeHandler('zip', e.target.value);
              setFieldValue('zip', e.target.value);
            }}
            className={classes.input}
            name="zip"
            type="text"
            label="Zip"
          />

          {status && status.success && (
            <Typography variant="subtitle1" className={classes.success}>
              {status.success}
            </Typography>
          )}

          {status && status.error && (
            <Typography variant="subtitle1" className={classes.error}>
              {status.error}
            </Typography>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddressForm;
