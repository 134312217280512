import rfaSurveyQuestions, { QuestionType, RFAQuestion, SurveyStep } from './common/rfaSurveyQuestions';
import { SelectRFAState } from './reducer';
import { Address, RFAContact, rfaContactSchema, RFADto, validateRFAAddress } from '@solvana/rfa-domain';
import { ValidationError } from 'yup';
import { ALL_RFA_STEPS } from './routes';

export const selectRFAAddress = ({ rfa }: SelectRFAState): Address => rfa.address;

export const selectRFAContact = ({ rfa }: SelectRFAState): RFAContact => rfa.contact;

export const selectCurrentRFAStep = ({ rfa }: SelectRFAState): number => rfa.currentStep;

export const selectIsLastStep = ({ rfa }: SelectRFAState): boolean => rfa.currentStep === rfaSurveyQuestions.length;

export const selectRFAFormData = ({ rfa }: SelectRFAState): RFADto => ({
  contact: rfa.contact,
  address: rfa.address,
  survey: Object.keys(rfa.survey).map((question) => ({
    question,
    answers: rfa.survey[question as RFAQuestion] || [''],
  })),
});

export const selectRFASubmitSuccess = ({ rfa }: SelectRFAState): boolean => rfa.submitSuccess;

export const selectIsRFAVisible = ({ rfa }: SelectRFAState): boolean => rfa.show;

export const selectIsSubmittingRFA = ({ rfa }: SelectRFAState): boolean => rfa.isSubmittingRFA;

export const selectRFASubmitError = ({ rfa }: SelectRFAState): string | null => rfa.rfaSubmitError;

export const selectRFAAddressErrors = ({ rfa }: SelectRFAState): { error: ValidationError | null } => {
  try {
    validateRFAAddress(rfa.address);
  } catch (error) {
    return { error };
  }

  return { error: null };
};

export const selectRFAContactErrors = (state: SelectRFAState): { error: ValidationError | null } => {
  try {
    rfaContactSchema.validateSync(state.rfa.contact);
  } catch (error) {
    return { error };
  }

  return { error: null };
};

export const selectRFAContactHasErrors = (state: SelectRFAState): boolean => {
  const { error } = selectRFAContactErrors(state);
  return Boolean(error);
};

export const selectRFAAddressHasErrors = (state: SelectRFAState): boolean => {
  const { error } = selectRFAAddressErrors(state);
  return Boolean(error);
};

export const selectRFASurveyQuestion = ({ rfa }: SelectRFAState): SurveyStep | undefined => {
  const index = rfaSurveyQuestions.findIndex((survey) => survey.step === ALL_RFA_STEPS[rfa.currentStep]);
  if (index < 0) return undefined;

  return rfaSurveyQuestions[index];
};

export const selectIsLastQuestion = (step: string): boolean => {
  const index = rfaSurveyQuestions.findIndex((survey) => survey.step === step);
  return index === rfaSurveyQuestions.length - 1;
};

export const selectRedirectPathForUnansweredQuestions = (
  state: SelectRFAState,
  question: string,
): string | undefined => {
  const currentQuestionIndex = rfaSurveyQuestions.findIndex((manifest) => question === manifest.question);
  if (currentQuestionIndex <= 0) return;

  const previousQuestions = rfaSurveyQuestions.slice(0, currentQuestionIndex);
  for (let i = 0; i < previousQuestions.length; i += 1) {
    if (QuestionType.Address === rfaSurveyQuestions[i].questionType) {
      const { error } = selectRFAAddressErrors(state);

      if (error) {
        return rfaSurveyQuestions[i].step;
      }
      continue;
    }

    const question = rfaSurveyQuestions[i].question;
    if (!state.rfa.survey[question]?.length) {
      return rfaSurveyQuestions[i].step;
    }
  }

  return undefined;
};

export const selectIsQuestionAnswered = ({ rfa }: SelectRFAState, question: RFAQuestion): boolean => {
  const answersForQuestion = rfa.survey[question] || {};
  return Boolean(Object.values(answersForQuestion).find((a) => a !== undefined));
};

export const selectIsAnswerSelected = ({ rfa }: SelectRFAState, question: RFAQuestion, answer: string): boolean => {
  const answersForQuestion = rfa.survey[question] || [];
  return Boolean(answersForQuestion.find((a) => a === answer));
};
