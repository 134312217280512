import React from 'react';
import { Container, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useStyles } from './styles';
import AddressForm from './AddressForm';
import { handleNextQuestionClick } from '../common/rfaSurveyQuestions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentRFAStep, selectRFAAddress, selectRFAAddressHasErrors } from '../selectors';
import { RFAAddressField, setAddressField, setAddressState } from '../actions';
import { State } from '@solvana/state-domain/dist';
import SelectButton from '../../../components/SelectButton';

const RFAAddress: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const address = useSelector(selectRFAAddress);
  const currentStep = useSelector(selectCurrentRFAStep);
  const isButtonDisabled = useSelector(selectRFAAddressHasErrors);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    handleNextQuestionClick(currentStep, dispatch);
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5">Installation Address</Typography>
      {!isMobile && (
        <Typography className={classes.subtitle} variant="subtitle1">
          Our design team uses satellite imagery to create custom solar options based on your site conditions - our
          proposals are <i>very</i> accurate.
        </Typography>
      )}

      <AddressForm
        address={address}
        onStateFieldChangeHandler={(state: State) => {
          dispatch(setAddressState(state));
        }}
        onFieldChangeHandler={(field: RFAAddressField, value: string) => {
          dispatch(setAddressField(field, value));
        }}
      />

      <SelectButton fullWidth disabled={isButtonDisabled} onClick={handleClick}>
        Next
      </SelectButton>
    </Container>
  );
};

export default RFAAddress;
