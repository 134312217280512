import { curry } from 'ramda';
import { RFASurveySteps } from '../../containers/RFA/routes';

export interface GTAGProperties {
  [key: string]: any;
}

export const track = curry((page: string, properties?: GTAGProperties) => {
  if (!(window as any).analytics) return;
  (window as any)?.analytics.track(page, properties);
});

export const trackDarkModeSet = track('darkModeSet');
export const trackLightModeSet = track('lightModeSet');

export interface RFAStartedProperties {
  location: string;
}
export const trackRFAStarted = (properties: RFAStartedProperties) => track('rfaStarted', properties);

interface RFAStepProperties {
  currentStep: RFASurveySteps | 'None';
  nextStep: RFASurveySteps | 'None';
}
export const trackRFAStep = (properties: RFAStepProperties) => track('rfaStep', properties);
export const trackRFAEarlyExit = (properties: GTAGProperties) => track('rfaEarlyExit', properties);
export const trackRFACompleted = track('rfaCompleted');
